import LancarNotasPage from "@/Pages/Notas/LancarNotasPage.vue";
import LancarNotasEletivasPage from "@/Pages/Notas/LancarNotasEletivasPage.vue";

export default [
  {
    path: "/gestoes-de-aulas/:gestaoDeAulasId/notas/lancar",
    name: "notas.lancarNotas",
    component: LancarNotasPage,
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulasId/notas/lancar/eletivas",
    name: "notas.lancarNotasEletivas",
    component: LancarNotasEletivasPage,
  },
];
