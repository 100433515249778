import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async ementaEletiva(gestaoDeEletivosId, bimestre) {
    const response = await Axios().get(`ementas/listar-ementa/${gestaoDeEletivosId}/${bimestre}`);
    return response.data;
  }

  // async planoMensal(gestaoDeAulaId, mes) {
  //   const response = await Axios().get(`planos/plano-mensal/${gestaoDeAulaId}/${mes}`);
  //   return response.data;
  // }

  // async planoMensalInfantil(gestaoDeAulaId, mes) {
  //   const response = await Axios().get(`planos/plano-mensal-infantil/${gestaoDeAulaId}/${mes}`);
  //   return response.data;
  // }

  async createOrUpdate(ementa) {
    const response = await Axios().post("ementas/criar-ementa", {
      ementa,
    });
    return response.data;
  }

  // async planoMensalCreateOrUpdate(plano) {
  //   const response = await Axios().post("planos/plano-mensal/create-or-update", {
  //     plano,
  //   });
  //   return response.data;
  // }

  // async planoMensalInfantilCreateOrUpdate(plano) {
  //   const response = await Axios().post("planos/plano-mensal-infantil/create-or-update", {
  //     plano,
  //   });
  //   return response.data;
  // }

  // async planoBimestralInfanil(gestaoDeAulaId, bimestre) {
  //   const response = await Axios().get(
  //     `planos/plano-bimestral-infantil/${gestaoDeAulaId}/${bimestre}`
  //   );
  //   return response.data;
  // }

  // async createOrUpdateInfantil(plano) {
  //   const response = await Axios().post("planos/criar-plano-bimestral-infantil", {
  //     plano,
  //   });
  //   return response.data;
  // }
}
export default new PlanosBimestraisService();
