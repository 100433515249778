import Axios from "@/plugins/Axios";

export class NotasService {
  async get(gestaoDeAulaId) {
    const response = await Axios().get(`notas/${gestaoDeAulaId}`);
    const payload = response.data;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.matriculas.map((matricula) => {
      if (!notas[matricula.id]) {
        notas[matricula.id] = {
          matricula_id: matricula.id,
          turma_id: payload.turma.id,
          disciplina_id: payload.disciplina.id,
        };
      }
      return matricula;
    });
    return { ...payload, notas };
  }

  async getNotasEletivas(gestaoDeAulaId) {
    const response = await Axios().get(`notas/notas-eletivas/${gestaoDeAulaId}`);
    const payload = response.data;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.matriculas.map((matricula) => {
      if (!notas[matricula.id]) {
        notas[matricula.id] = {
          matricula_id: matricula.id,
          disciplina_id: payload.disciplina.id,
          instrutor_disciplina_matricula_id: payload.gestaoDeAula.id,
        };
      }
      return matricula;
    });
    return { ...payload, notas };
  }

  async salvarNotas(gestaoDeAulaId, notas) {
    await Axios().patch(`notas/${gestaoDeAulaId}`, notas);
  }

  async salvarNotasEletivas(gestaoDeAulaId, notas) {
    await Axios().patch(`notas/notas-eletivas/${gestaoDeAulaId}`, notas);
  }
}
export default new NotasService();
